







































import { Mixin } from "@/core/mixins/mixin";

import { Component, Mixins } from "vue-property-decorator";
import {
  Col,
  Row,
  Tab,
  Tabs,
  Popup,
  Icon,
  Popover,
  Button,
  Image,
  List,
  Sticky,
} from "vant";
import SearchLocation from "@/views/Classify/SearchLocation.vue";
import Show from "@/views/Show/Show.vue";
import CategorySelector from "@/components/CategorySelector.vue";
import { classForm } from "@/shims-video";

@Component({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popover.name]: Popover,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Image.name]: Image,
    [List.name]: List,
    [Sticky.name]: Sticky,
    Show,
    SearchLocation,
    CategorySelector,
  },
})
export default class ChannelDistributionCommonPage extends Mixins(Mixin) {
  public caterId: string | null = "";
  public classList: dictionary.ProductCategoryDto[] = [];
  public catelist: { code: string; name: string }[] = [];

  mounted(): void {
    window.document.title =
      (this.LocationModule.cityName || "") + "热门演出在线选座网上售票";
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    this.getClassList();
  }

  changeTab(name: string): void {
    this.caterId = name;
  }

  // 返回主页
  returnHome(): void {
    this.$router.go(-1);
  }
  // 获取全部分类
  getClassList(): void {
    this.$api.dictionaryApi.performance.searchCategories(({ data }) => {
      this.classList = data;
    });
  }

  /**
   * 打开分类页中的右侧弹框
   */
  openCategorySelector(): void {
    let categorySelector = this.$refs["category-selector"] as CategorySelector;
    if (categorySelector) {
      categorySelector.open();
    }
  }

  clickSearch(): void {
    this.$router.push(`/channeldistributionsearch`);
  }

  /**
   * 右侧弹框中提交了筛选条件，调用演出列表中的方法根据查询表单查询数据
   */
  submitSelect(val: classForm): void {
    let showList = this.$refs["show-list"] as Show;
    if (showList) {
      showList.queryData(val);
    }
  }
}
